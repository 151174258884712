









































































































import Widget from '@/components/Widget/Widget.vue'
import { Component, Ref } from 'vue-property-decorator'
import Profile from '@/models/Profile'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
  },
})
export default class ProfileHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public profile: Profile = new Profile()

  public sort_by: string = 'created_at'

  public sort_desc: boolean = true

  public query: string[] = []

  public page_size: number = 25

  public page: number = 1

  public records: number = 0

  public loading: boolean = false

  public modal: any = {
    delete: false,
  }

  public fields: any = [
    {
      key: 'name',
      label: 'Name',
      sortable: true,
      show: true,
    },
    {
      key: 'company_id',
      label: 'Company',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'station_id',
      label: 'Station',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'created_at',
      label: 'Created At',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'updated_at',
      label: 'Update At',
      sortable: true,
      class: 'text-center',
      show: false,
    },
    {
      key: 'action',
      label: 'Action',
      sortable: false,
      show: true,
    },
  ]

  public query_settings: any = {
    company_fields: [
      {
        name: 'company',
        key: 'company_id',
      },
      {
        name: 'station',
        key: 'station_id',
        type: 'station',
      },
    ],
    custom_fields: [
      {
        name: 'station:n/a',
        value: 'station_id:',
      },
    ],
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public profiles(context: any) {
    this.loading = true
    return Profile.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: context.filter,
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  public confirmDelete(profile: Profile) {
    this.profile = profile
    this.modal.delete = true
  }

  public deleteUser() {
    this.profile.delete().then(() => {
      this.dataTable.refresh()
    })
  }

  public createProfile() {
    this.$router.push('/app/profile')
  }

  public editProfile(id: string) {
    this.$router.push(`/app/profile/${id}`)
  }

  public created() {
    this.records = this.profiles.length
  }
}
